<template>
    <section class="wow slideInUp">
        <h2>Un prototipo vale más que 1.000 reuniones</h2>
        <p>Ya sea que estés buscando crear un producto desde cero o bien mejorar la experiencia de uno ya existente, podemos ayudarte a saber si el Design Sprint es para vos.</p>
        <a class="button" href="https://forms.gle/fR4QceUf82aGGN54A" target="_blank">Accedé al test gratuito</a>
    </section>
</template>
<style scoped>
    section {
        min-height: 100vh;
        background-image: url('../../assets/img/bkg-blue-mobile.png');
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 40px;
        margin-bottom: 0px;
    }
    h2, p {
        color: white;
    }
    h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .button {
        color: #2D4AD1;
        background: white;
        border-radius: 3px;
        font-weight: 700;
        padding: 16px;
    }
    @media all and (min-width: 768px) {
        section {
            background-image: url('../../assets/img/bkg-blue.png');
            min-height: 60vh;
        }
    }
    @media all and (min-width: 1200px) {
        h2 {
            font-size: 42px;
        }
        p {
            font-size: 20px;
            max-width: 1200px;
            margin: 0 auto 40px auto;
            line-height: 26px;
        }
        a {
            font-size: 18px;
        }
    }
</style>