<template>
    <section>
        <div class="title wow slideInUp">
            <h2>Contanos un poco sobre vos</h2>
            <p>Para nuestro equipo lo primero son las personas. ¿Nos ayudás a saber más sobre vos?</p>
        </div>
        <form>
            <div class="row">
                <div class="field required">
                    <label>Mi nombre completo es...</label>
                    <div class="input-group" :class="{'error': errors.name}">
                        <input @input="handleNameChange" type="text" placeholder="Ingresá tu nombre completo"/>
                        <span v-if="!errors.name">*Requerido</span>
                        <span class="error" v-else>Debés ingresar tu nombre</span>
                    </div>
                </div>
                <div class="field required">
                    <label>Me pueden contactar en:</label>
                    <div class="input-group" :class="{'error': errors.email}">
                        <input type="text" @input="handleEmailChange" placeholder="Ingresá tu email de contacto"/>
                        <span v-if="!errors.email">*Requerido</span>
                        <span class="error" v-else>Debés ingresar un mail válido</span>
                    </div>
                </div> 
                <div class="field">
                    <label>Mi Linkedin es:</label>
                    <div class="input-group" :class="{'error': errors.linkedin}">
                        <input type="text" @input="handleLinkedinChange" placeholder="Ingresá la URL de tu Linkedin"/>
                        <span v-if="errors.linkedin" class="error">Debés ingresar una URL válida</span>
                    </div>
                </div> 
            </div>
            <div class="field required">
                <label>Y una breve intro sobre mi y cómo llegué hasta acá:</label>
                <div class="input-group" :class="{'error': errors.intro}">
                    <textarea  @input="handleIntroChange" placeholder="Contanos un poco sobre vos, 250 caractéres máximo."></textarea>
                    <span v-if="!errors.intro">*Requerido</span>
                    <span v-else class="error">Debés escribir una intro</span>
                </div>
            </div> 
        </form>
    </section>
</template>
<script>
export default {   
    props: ['errors', 'subscriptionSuccess'],
    watch: {
        subscriptionSuccess: function (value) {
            console.log(value)
            if (value) {
                this.name = ""
                this.email = ""
                this.linkedin = ""
                this.intro = ""
            }
        },
        errors: function (value) {
            console.log(value)
        }
    }, 
    methods: {
        handleNameChange (event) {
            this.$emit('change', {name: 'name', value: event.target.value})
        },
        handleEmailChange (event) {
            this.$emit('change', {name: 'email', value: event.target.value})
        },
        handleLinkedinChange (event) {
            this.$emit('change', {name: 'linkedin', value: event.target.value})
        },
        handleIntroChange (event) {
           this.$emit('change', {name: 'intro', value: event.target.value}) 
        }
    }
}
</script>
<style scoped>
    section {
        padding: 0 40px;
    }
    h2 {
        font-weight: bold;
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 24px;
    }
    form {
        margin-top: 20px;
    }
    .field:not(first-child) {
        margin-top: 32px;
    }
    label {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 12px;
        display: block;
    }
    input, textarea {
        width: 100%;
        height: 40px;
        border: 1px solid gray;
        border-radius: 5px;
        padding-left: 8px;
        font-size: 16px;
        font-family: 'Lato'
    }
    textarea {
        padding-top: 8px;
        height: 180px;
    }
    .input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .error {
        align-items: flex-start;
    }
    .error input, .error textarea {
        border-color: #FE6D70;
    }
    .input-group span {
        color: #FE6D70;
        font-weight: bold;
        font-size: 14px;
        line-height: 36px;
    }
    ::placeholder {
        font-size: 16px;
        font-family: 'Lato'
    }
    p {
        font-family: 'Lato';
    }
    @media all and (min-width: 768px) {
        .row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            padding: 0 120px;
            margin-bottom: 60px;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            width: 40%;
        }
    }
    @media all and (min-width: 1600px) {
        section {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>