<template>
<section id="services">
    <h2>Nuestros servicios son herramientas</h2>
    <div class="grid">
        <div class="wow fadeIn">
            <h3>Estrategia de producto y negocio</h3>
            <p>Innovar es embarcarse en una gran aventura, necesitás chequear tu brújula antes de salir.</p>
            <ul>
                <li>Product Strategy</li>
                <li>Product Discovery</li>
            </ul>
        </div>
        <div class="wow fadeIn" data-wow-delay=".2s">
            <h3>Co-creación de experiencias</h3>
            <p>Ninguno de nosotros es tan inteligente como todos nosotros y si nos movemos juntos, el éxito viene solo.</p>
            <ul>
               <li>Experiencia de persona usuaria (UX)</li> 
               <li>Design Sprint</li> 
            </ul>            
        </div>
        <div class="wow fadeIn" data-wow-delay=".4s">
            <h3>Desarrollo</h3>
            <p>El toque de magia que toda experiencia necesita. Hacemos realidad la transformación a través del código.</p>
            <ul>
                <li>Desarrollá sitios web</li>
                <li>Desarrollá para móviles</li>
            </ul>
        </div>
    </div>
</section>
</template>
<style scoped>
section {
    padding: 0 40px;
}
li {
    list-style: none;
}
h2 {
    margin-bottom: 32px;
}
h3 {
    color: #2D4AD1;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
}
p, li {
    font-size: 14px;
    font-family: 'Lato';
}
p {
    margin-bottom: 16px;
    line-height: 20px;
}
li {
    font-weight: 800;
}
li:before {
    content: "+";
    font-weight: 800;
    margin-right: 8px;
}
li:first-child {
    margin-bottom: 20px;
}
.grid div {
    margin-bottom: 54px;
}
@media all and (min-width: 768px) {
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
    }
    li:first-child {
        margin-bottom: 16px;
    }
}
@media all and (min-width: 1200px) {
    section {
        padding: 0 120px;
        margin-bottom: 60px;
    }
    .grid {
        grid-gap: 60px;
    }
    h2 {
        font-size: 42px;
        width: 60%;
        margin-bottom: 44px;
    }
    h3 {
        font-size: 20px;
    }
    p, li {
        font-size: 18px;
    }
    p {
        line-height: 28px;
    }
    li:first-child {
        margin-bottom: 20px;
    }
}
@media all and (min-width: 1600px) {
    section {
        max-width: 1300px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>